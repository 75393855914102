import { Picture, Text } from '@components/ui'
import { appendRefParameter } from '@lib/utils/tools'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import Icon from '@components/icons'

const Index = ({ data, pageHandle, isMobile, setYouTubeId, gtmRef }) => {
  const { setRelativeLink } = useRelativeLink()

  return (
    <div className="relative">
      <div className="aspect-h-[780] aspect-w-[1920] relative w-full md:aspect-h-[480] md:aspect-w-[390]">
        {data?.video ? (
          <video
            className="h-full w-full object-cover"
            preload="none"
            playsInline
            muted
            autoPlay
            loop={true}
            poster={isMobile ? data?.imageMob || data?.image : data?.image}
            src={isMobile ? data?.videoMob || data?.video : data?.video}
          ></video>
        ) : (
          <Picture
            source={`${data?.image}, ${data?.imageMob || data?.image} 769`}
            className="h-full w-full object-cover"
            imgClassName="h-full w-full object-cover"
          />
        )}
        <div
          className="absolute left-0 top-0 h-full w-full"
          style={{
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%)',
          }}
        ></div>
      </div>
      <div className="layer absolute left-0 top-1/2 w-full -translate-y-1/2">
        <div className="content">
          <div className="mx-auto w-[50%] text-center md:w-full">
            {data?.preTitle && (
              <Text
                html={data?.preTitle}
                className="mb-[8px] text-[20px] font-[500] leading-[1.4] text-[#fff] xl:text-[14px]"
              />
            )}
            {data?.title && (
              <Text
                html={data?.title}
                className="title text-[40px] font-[700] leading-[1.2] text-[#fff] xl:text-[32px] [&_img]:inline-block"
              />
            )}
            {data?.buttons && (
              <div className="mt-[48px] flex justify-center gap-[32px] text-[16px] font-[600] text-[#fff] xl:mt-[24px] xl:gap-[24px] xl:text-[16px]">
                {data?.buttons?.map((item, index) => {
                  const link = item?.link
                    ? appendRefParameter({
                        url: setRelativeLink({ link: item?.link }),
                        refValue: gtmRef || pageHandle,
                      })
                    : null
                  return (
                    <a
                      key={'HomeBrandVideo-button-' + index}
                      className="flex cursor-pointer items-center transition-all duration-[0.4s] [&:hover]:text-[#00BEFA] [&:hover_path]:fill-[#00BEFA]"
                      onClick={() => {
                        if (item?.youTubeId) {
                          setYouTubeId(item?.youTubeId)
                          pageGTMEvent({
                            event: 'ga4Event',
                            event_name: 'lp_button',
                            event_parameters: {
                              page_group: 'Activity Page_' + pageHandle,
                              position: item?.youTubeTitle || '',
                              button_name: 'video',
                            },
                          })
                        }
                      }}
                      href={link}
                    >
                      <span dangerouslySetInnerHTML={{ __html: item?.label }} />
                      <svg
                        className="mb-[2px]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.62201 11.457C6.32261 11.7564 6.32261 12.2418 6.62201 12.5412C6.92141 12.8406 7.40684 12.8406 7.70624 12.5412L11.7062 8.5412C12.0056 8.2418 12.0056 7.75638 11.7062 7.45697L7.70624 3.45697C7.40684 3.15757 6.92141 3.15757 6.62201 3.45697C6.32261 3.75638 6.32261 4.2418 6.62201 4.5412L10.0799 7.99909L6.62201 11.457Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Index
